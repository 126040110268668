// Initialize first
@import '../init';

// Import files
@import 'home';

// Add your style here
.home {
  // Versi Homepage
  &--v1 {
    position: relative;
    width: 100%;
    padding-bottom: 40px;

    @include media-breakpoint-down(xl) {
      padding: 0px 20px;
    }
  }

  // Component Homepage
  &__title {
    position: relative;
    display: flex;
    padding-top: 180px;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;

    h1 {
      color: $color-white;
      max-width: 823px;
    }
    p {
      max-width: 452px;
      color: $color-lighter-grey;
      margin-top: 20px;
    }
  }

  &__button {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 44px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      width: 100%;

      .btn {
        width: 100%;
      }
    }

    .btn {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -khtml-border-radius: 10px;
      transition: 0.2s;
    }
  }

  &__ilustration {
    position: relative;
    margin: auto;
    margin-top: toRem(48);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: calc(1016 / 1100 * 100%);
      height: auto;
      box-shadow: $shadow-lg;
    }
  }

  &__content-why {
    &--article {
      position: relative;
      height: 597px;
      display: flex;
      margin-top: 64px;
      gap: 24px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        height: 100%;
      }
    }

    &__details {
      &--about {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0.5;
        gap: 24px;
      }

      &__detail {
        &--about {
          @include media-breakpoint-down(lg) {
            display: flex;
            text-align: center;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }

          h4 {
            color: $color-dark-blue;
            margin-top: 24px;
            text-transform: capitalize;
          }
          p.text-m-regular {
            color: $color-grey;
            margin-top: 10px;
          }
        }

        &--about-1 {
          text-align: center;

          .icon-large {
            margin: auto;
          }
          h4 {
            color: $color-dark-blue;
            margin: auto;
            margin-top: 24px;
            text-transform: capitalize;
          }
          p.text-m-regular {
            color: $color-grey;
            margin: auto;
            margin-top: 10px;
            max-width: 287px;
            @include text-shorten($line: 3);
          }
        }
      }
    }

    &__illustration {
      position: relative;
      flex: 1;
      height: 100%;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      img {
        position: absolute;
        height: 120%;
        width: 120%;
        top: -90px;
        left: -50px;
      }
    }
  }

  &__btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @include media-breakpoint-down(xl) {
      display: none;
    }

    &--about {
      margin-left: auto;
      margin-right: auto;
      margin-top: -24px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__btn-2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

.m-b-4 {
  margin-bottom: -210px;

  @include media-breakpoint-down(lg) {
    margin-bottom: -180px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: -160px;
  }
}
